<template>
  <div>
    <div class="d-flex justify-space-between align-center mb-2 mt-6">
      <div class="input-wrap">
        <h5 class="mb-2">Ventas por Vendedor</h5>
      </div>
      <div class="action-btn-wrap">
        <!-- <v-btn v-if="records.length > 0" color="accent" @click="clickDownloadExcel()">
					<v-icon left small>mdi-file-excel</v-icon> Exportar Excel
				</v-btn> -->
      </div>
    </div>
    <v-card color="secondary" class="pa-3">
      <v-row dense>
        <v-col cols="12" xl="2" lg="2" md="2" sm="2">
          <div class="form-group">
            <label class="control-label">Desde</label>
            <el-date-picker
              v-model="search.start"
              type="date"
              value-format="yyyy-MM-dd"
              format="dd/MM/yyyy"
              :picker-options="pickerOptions"
              :clearable="false"
              :editable="false"
            ></el-date-picker>
          </div>
        </v-col>
        <v-col cols="12" xl="2" lg="2" md="2" sm="2">
          <div class="form-group">
            <label class="control-label">Hasta</label>
            <el-date-picker
              v-model="search.end"
              type="date"
              value-format="yyyy-MM-dd"
              format="dd/MM/yyyy"
              :picker-options="pickerOptionsDates"
              :clearable="false"
              :editable="false"
            ></el-date-picker>
          </div>
        </v-col>
        <v-col cols="12" xl="2" lg="2" md="2" sm="2">
          <div class="form-group">
            <label class="control-label">Moz@</label>
            <el-select v-model="search.waiter_id" clearable>
              <el-option
                v-for="user in waiters"
                :key="user.id"
                :label="user.name"
                :value="user.id"
              ></el-option>
            </el-select>
          </div>
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="4">
          <div class="form-group">
            <label class="control-label">Producto</label>
            <el-select
              v-model="search.item_id"
              filterable
              remote
              reserve-keyword
              placeholder="Buscar..."
              :remote-method="searchItems"
              :loading="loadingSearchItems"
              loading-text="Cargando..."
              clearable
            >
              <el-option
                v-for="item in items"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </v-col>
        <v-col cols="12" xl="2" lg="2" md="2" sm="2">
          <el-button
            type="primary"
            icon="el-icon-search"
            class="btn-block mt-5"
            :loading="loading"
            @click="getData"
          ></el-button>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          cols="12"
          xl="6"
          lg="6"
          md="6"
          sm="6"
          offset-xl="3"
          offset-lg="3"
          offset-md="3"
          offset-sm="3"
          v-for="(row, index) in records"
          :key="index"
        >
          <v-card color="secondary">
            <v-simple-table dark fixed-header dense class="mt-3">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center" colspan="4">
                      <strong class="white--text"><h6>Mozo: {{ row.name }}</h6></strong>
                    </th>
                  </tr>
                  <tr>
                    <th class="text-right">N°</th>
                    <th>Producto</th>
                    <th class="text-right">Cant.</th>
                    <th class="text-right">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, ind) in row.products" :key="ind * 500 * -2">
                    <td class="text-right">
                      {{ ind + 1 }}
                    </td>
                    <td>{{ item.product.replace('"', '').replace('"', '') }}</td>
                    <td class="text-right">{{ item.qt }}</td>
                    <td class="text-right" width="22%">
                      <strong>S/ {{ item.tt }}</strong>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="2"></td>
                    <td>
                      <h6 class="font-black white--text text-right">
                        Total:
                      </h6>
                    </td>
                    <td class="text-right">
                      <h6 class="white--text">S/ {{ row.total_orders }}</h6>
                    </td>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          xl="6"
          lg="6"
          md="6"
          offset-xl="3"
          offset-lg="3"
          offset-md="3"
        >
          <v-card color="secondary">
            <v-simple-table dark fixed-header dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center" colspan="4">
                      <strong class="white--text">VENTA RÁPIDA</strong>
                    </th>
                  </tr>
                  <tr>
                    <th class="text-right">N°</th>
                    <th>Producto</th>
                    <th class="text-right">Cant.</th>
                    <th class="text-right">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, ind) in fast_sales" :key="ind">
                    <td class="text-right">
                      {{ ind + 1 }}
                    </td>
                    <td>{{ item.product.replace('"', '').replace('"', '') }}</td>
                    <td class="text-right">{{ item.qt }}</td>
                    <td class="text-right" width="22%">
                      <strong>S/ {{ item.tt }}</strong>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="2"></td>
                    <td>
                      <h6 class="font-black accent--text text-right">
                        Total:
                      </h6>
                    </td>
                    <td class="text-right">
                      <h6 class="accent--text">S/ {{ fast_sales_total }}</h6>
                    </td>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          xl="6"
          lg="6"
          md="6"
          offset-xl="3"
          offset-lg="3"
          offset-md="3"
        >
          <v-card color="secondary">
            <v-simple-table dark fixed-header dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center" colspan="4">
                      <strong class="white--text">TOTAL PRODUCTOS VENDIDOS</strong>
                    </th>
                  </tr>
                  <tr>
                    <th class="text-right">N°</th>
                    <th>Producto</th>
                    <th class="text-right">Cant.</th>
                    <th class="text-right">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, ind) in all_sales" :key="ind">
                    <td class="text-right">
                      {{ ind + 1 }}
                    </td>
                    <td>{{ item.product.replace('"', '').replace('"', '') }}</td>
                    <td class="text-right">{{ item.qt }}</td>
                    <td class="text-right" width="22%">
                      <strong>S/ {{ item.tt }}</strong>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          xl="6"
          lg="6"
          md="6"
          offset-xl="3"
          offset-lg="3"
          offset-md="3"
        >
          <v-card color="secondary">
            <v-simple-table dark fixed-header dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td width="78%">
                      <h4 class="font-black success--text text-right">
                        Total:
                      </h4>
                    </td>
                    <td class="text-right">
                      <h4 class="success--text">S/ {{ balance }}</h4>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  data() {
    return {
      resource: "reports/sales-by-seller",
      records: [],
      fast_sales: [],
      all_sales: [],
      waiters: [],
      items: [],
      loadingSearchItems: false,
      loading: false,
      balance: 0.0,
      fast_sales_total: 0.0,
      search: {
        start: dayjs().format("YYYY-MM-DD"),
        end: dayjs().format("YYYY-MM-DD"),
        waiter_id: null,
      },
      pickerOptions: {
        disabledDate: (time) => {
          time = dayjs(time).format("YYYY-MM-DD");
          return dayjs().format("YYYY-MM-DD") < time;
        },
      },
      pickerOptionsDates: {
        disabledDate: (time) => {
          time = dayjs(time).format("YYYY-MM-DD");
          return this.search.start > time;
        },
      },
    };
  },
  created() {
    this.getTables();
  },
  methods: {
    customIndex(index) {
      return (
        this.pagination.per_page * (this.pagination.current_page - 1) +
        index +
        1
      );
    },
    getTables() {
      this.$http.get(`/${this.resource}/tables`).then((resp) => {
        this.waiters = resp.data.waiters;
      });
    },
    async getData() {
      this.loading = true;
      await this.$http
        .post(`/${this.resource}/records`, this.search)
        .then((resp) => {
          this.records = resp.data.data.records;
          this.fast_sales = resp.data.data.fast_sales;
          this.all_sales = resp.data.data.all_sales;
          this.balance = resp.data.data.total;
          this.fast_sales_total = resp.data.data.fast_sales_total;

          this.loading = false;
        });
    },
    searchItems(input) {
      if (input.length > 2) {
        this.loadingSearchItems = true;
        let parameters = `input=${input}`;
        this.$http
          .get(`/${this.resource}/search/items?${parameters}`)
          .then((resp) => {
            this.items = resp.data;
            this.loadingSearchItems = false;
          });
      } else {
        this.items = [];
      }
    },
    clickDownloadExcel() {
      this.$http({
        url: `/${this.resource}/excel`,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "ReporteComandas.xlsx");
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
  },
};
</script>
